import { NavLink } from "react-router-dom";
import logo from '../../logo.png';
// import infoIcon from './ic_info.svg';
// import helpIcon from './ic_help.svg';
// import SVG from 'react-inlinesvg';
import { logout, checkAdmin } from '../../auth';
import { gql, useMutation } from '@apollo/client';

interface NavDrawerProps {
  isOpen?: boolean;
  isSignedIn?: boolean;
  isAdmin?: boolean;
  onClose: () => void;
  onLogout: () => void;
}
export function NavDrawer({ isOpen, isSignedIn, isAdmin, onClose, onLogout }: NavDrawerProps) {

  const STOP_TRACKING = gql`
    mutation StopTracking {
      stopTracking {
        id
        name
        color
        lat
        lng
        online
      }
    }
  `;

  const [stopTracking] = useMutation(STOP_TRACKING);

  const closeDrawer = () => {
    onClose();
  }

  const translate = isOpen ? 'translate-x-0' : '-translate-x-full';

  const signOut = async () => {
    const isAdmin = checkAdmin();
    if (!isAdmin) {
      await stopTracking();
    }
    logout();
    onLogout();
  }

  return (
    <div className="fixed left-0 top-0 right-0 bottom-0 pointer-events-none text-sm text-neutral">
      {isOpen &&
        <div className="absolute bg-gray-600 bg-opacity-90 w-full h-full pointer-events-auto" onClick={() => closeDrawer()}/>
      }
      <nav className={`absolute left-0 h-full bg-white w-80 transform ${translate} transition duration-300 ease-in-out pointer-events-auto text-center`}>
        <img src={logo} alt="LBI Shuttle" className="w-40 mx-auto my-5"/>
        <div className="px-4 mb-4">
          {!isSignedIn &&
            <NavLink className="border-gray-300 rounded-full border w-full block p-2" to="/login" onClick={() => closeDrawer()}>Admin Sign In</NavLink>
          }
          {isSignedIn &&
            <button className="border-gray-300 rounded-full border w-full block p-2" onClick={() => signOut()}>Sign Out</button>
          }
        </div>
        {/*
        <ul className="h-full border-t border-gray-300 text-left">
          <li>
            <NavLink className="flex p-4 items-center" to="/about" onClick={() => closeDrawer()}>
              <SVG src={infoIcon} />
              <span className="ml-4">About LBI Shuttle</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="flex p-4 items-center" to="/help" onClick={() => closeDrawer()}>
              <SVG src={helpIcon} />
              <span className="ml-4">Help &amp; Feedback</span>
            </NavLink>
          </li>
        </ul>
        */}
        <ul className="fixed bottom-0 w-full flex border-t border-gray-300 text-xs justify-center p-4">
          <li>
            <a href="https://lbishuttle.com" target="_blank" rel="noreferrer">
              <span>Privacy Policy</span>
            </a>
          </li>
          <li className="mx-2">•</li>
          <li>
            <a href="https://lbishuttle.com" target="_blank" rel="noreferrer">
              <span>Terms of Service</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

NavDrawer.defaultProps = {
  isOpen: false
};

export default NavDrawer;
