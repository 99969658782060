import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import SVG from 'react-inlinesvg';
import backIcon from '../../ic_chevron_left.svg';
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Shuttle from '../../models/Shuttle';
import User from '../../models/User';

interface DriverData {
  drivers: [User];
}

export const CREATE_SHUTTLE = gql`
  mutation CreateShuttle($shuttle: ShuttleInput!, $file: Upload) {
    createShuttle(shuttle: $shuttle, file: $file) {
      id
      name
      color
      photo
      sponsorName
      sponsorWebsite
      userId
    }
  }
`;

export const GET_DRIVERS = gql`
  query GetDrivers {
    drivers {
      id
      displayName
    }
  }
`;

export function AddShuttle() {
  const [shuttle, setShuttle] = useState<Shuttle>({id: 0, name: '', color: '', photo: '', sponsorName: '', sponsorWebsite: '', lat: 0, lng: 0, distance: 0, userId: 0, online: false, updatedAt: new Date()});
  const [createShuttle, { loading: mutationLoading, error: mutationError, data: updatedData }] = useMutation(CREATE_SHUTTLE);
  const [file, setFile] = useState<File>();

  const { loading: driversLoading, error: driversError, data: driversData } = useQuery<DriverData>(GET_DRIVERS);
  const [drivers, setDrivers] = useState<[User]>();
  const [driverId, setDriverId] = useState<number>();

  const history = useHistory();

  useEffect(() => {
    if (updatedData) {
      console.log('shuttle created: ', updatedData)
      history.push('/shuttles');
    }
    if (driversData) {
      setDrivers(driversData.drivers);
    }
  }, [history, updatedData, driversData])

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (shuttle) {
      setShuttle({...shuttle, [event.target.name]: event.target.value});
    }
  }

  const handleDriverChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setDriverId(parseInt(event.target.value));
  }

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (shuttle) {
      console.log('update shuttle')
      createShuttle({variables: {
        shuttle: { 
          name: shuttle.name, color: shuttle.color, sponsorName: shuttle.sponsorName, sponsorWebsite: shuttle.sponsorWebsite, userId: driverId
        },
        file: file
      }});
    }
    event.preventDefault();
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { validity, files } = event.target;
    const file = files?.item(0);
    if (file && validity.valid) {
      setFile(file);
    }
  }

  return (
    <div className="text-neutral">
      <header className="h-14 border-b border-gray-300 fixed w-full top-0 bg-white flex justify-center items-center">
        <a href="/shuttles" className="absolute left-5"><SVG src={backIcon} /></a>
        <h1>Add Shuttle</h1>
      </header>
      <div className="mt-14 p-4">
        {shuttle &&
          <form onSubmit={handleOnSubmit}>
            {mutationError && <p className="bg-red-100 text-red-800 p-1">{mutationError.message}</p>}

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="name">Name</label>
              <input id="name" name="name" type="text" className="block w-full outline-none" value={shuttle.name} onChange={handleOnChange} />
            </div>

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="color">Color</label>
              <input id="color" name="color" type="text" className="block w-full outline-none" value={shuttle.color} onChange={handleOnChange} />
            </div>

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="sponsorName">Sponsor Name</label>
              <input id="sponsorName" name="sponsorName" type="text" className="block w-full outline-none" value={shuttle.sponsorName} onChange={handleOnChange} />
            </div>

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="color">Sponsor Website</label>
              <input id="sponsorWebsite" name="sponsorWebsite" type="text" className="block w-full outline-none" value={shuttle.sponsorWebsite} onChange={handleOnChange} />
            </div>

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="photo">Photo</label>
              <input id="photo" type="file" onChange={handleFileChange} />
            </div>
            {shuttle.photo && 
              <img src={shuttle.photo} alt="Shuttle" className="rounded-lg mb-4" />
            }

            {!driversLoading && !driversError && drivers &&
              <div className="border border-gray-300 rounded-lg p-2 mb-4">
                <label className="text-xs block" htmlFor="driverId">Driver</label>
                <select id="driverId" value={driverId} className="w-full" onChange={handleDriverChange}>
                  <option>Select a driver</option>
                  {drivers.map((d) => (
                    <option key={d.id} value={d.id}>{d.displayName}</option>
                  ))}
                </select>
              </div>
            }

            {mutationLoading &&
              <button type="submit" disabled className="rounded-lg bg-primary text-white block w-full text-lg p-4 font-semibold mb-3">Saving...</button>
            }
            {!mutationLoading &&
              <button type="submit" className="rounded-lg bg-primary text-white block w-full text-lg p-4 font-semibold mb-3">Save</button>
            }
          </form>
        }
      </div>
    </div>
  );
}

export default AddShuttle;
