import React from 'react';
import TimeAgo from 'react-timeago';
import Post from '../../models/Post';

interface PostItemParams {
  post: Post;
}

export function PostItem({ post }: PostItemParams) {
  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold leading-snug">{post.title}</h2>
      <p className="text-sm font-light leading-snug mb-1">{post.body}</p>
      <TimeAgo className="block text-xs font-light leading-snug mb-4" date={post.publishedAt} />
      {post.photo &&
        <img src={post.photo} alt={post.title} className="rounded-lg" />
      }
    </div>
  );
}

export default PostItem;
