import React, { useEffect, useState } from 'react';
import {
  ApolloClient,
  // createHttpLink,
  InMemoryCache,
  ApolloProvider,
  from,
  ApolloLink,
  // Observable,
  fromPromise,
  // Operation
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
// import { RetryLink } from "@apollo/client/link/retry";
import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import News from '../News/News';
import Shuttles from '../Shuttles/Shuttles';
import BottomNav from '../BottomNav/BottomNav';
import NavDrawer from '../NavDrawer/NavDrawer';
import NavDrawerButton from '../NavDrawerButton/NavDrawerButton';
import Login from '../Login/Login';
import { checkAdmin, checkSignedIn, refresh } from '../../auth';
import EditNews from '../EditNews/EditNews';
import { createUploadLink } from 'apollo-upload-client';
import AddNews from '../AddNews/AddNews';
import Drivers from '../Drivers/Drivers';
import AddDriver from '../AddDriver/AddDriver';
import EditDriver from '../EditDriver/EditDriver';
import ProtectedRoute, { ProtectedRouteProps } from '../ProtectedRoute/ProtecteRoute';
import EditShuttle from '../EditShuttle/EditShuttle';
import AddShuttle from '../AddShuttle/AddShuttle';
import Tracker from '../Tracker/Tracker';
import About from '../About/About';
import Help from '../Help/Help';
import { Wrapper } from "@googlemaps/react-wrapper";

const httpLink = (createUploadLink({ uri: `${process.env.REACT_APP_HOST}/graphql` }) as unknown) as ApolloLink;

const getNewToken = async () => {
  try {
    const { token } = await refresh();
    console.log('received new token', token);
    return `Bearer ${token}`;
  } catch (err) {
    console.error('could not get new token', err);
    return null;
  }
}

let isRefreshing = false;
let pendingRequests: Function[] = [];

const setIsRefreshing = (value: boolean) => {
  isRefreshing = value
}

const addPendingRequest = (pendingRequest: Function) => {
  pendingRequests.push(pendingRequest)
}

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback())
  pendingRequests = []
}

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const { message, locations, path, extensions } = err;
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      console.log('code:', extensions?.code);
      switch (extensions?.code) {
        case 'UNAUTHENTICATED':
          if (!isRefreshing) {
            // Modify the operation context with a new token
            const oldHeaders = operation.getContext().headers;

            setIsRefreshing(true);
            return fromPromise(
              getNewToken().catch(() => {
                console.error('could not get new token')
                resolvePendingRequests();
                setIsRefreshing(false);
                return forward(operation);
              }),
            ).flatMap((newToken) => {
              console.log('successfully refreshed token')
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: newToken,
                },
              });
              resolvePendingRequests();
              setIsRefreshing(false);
              return forward(operation);
            });
          } else {
            console.log('add promise')
            return fromPromise(
              new Promise<void>((resolve) => {
                addPendingRequest(() => resolve())
              }),
            ).flatMap(() => {
              return forward(operation);
            })
          }
        }
    }
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
});

// const retryLink = new RetryLink({
//   delay: {
//     initial: 300,
//     max: Infinity,
//     jitter: true
//   },
//   attempts: {
//     max: 5,
//     retryIf: (error, operation) => handleRetry(error, operation)
//   }
// })

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  // link: from([retryLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache()
});


// const handleRetry = async (error: any, operation: Operation) => {
//   console.log('handle retry');
//   let requiresRetry = false;
//   if (error.statusCode === 401) {
//     requiresRetry = true;
//     if (!isRefreshing) {
//       isRefreshing = true;
//       // Modify the operation context with a new token
//       const oldHeaders = operation.getContext().headers;
//       const token = await getNewToken();
//       console.log('unauthenticated, new token=', token);
//       operation.setContext({
//         headers: {
//           ...oldHeaders,
//           authorization: token,
//         },
//       });
//       isRefreshing = false;
//     }
// }
//   return requiresRetry;
// }

export function App() {
  const [isDrawerOpen, toggleDrawer] = useState(false);
  const [isSignedIn, setSignedIn] = useState(checkSignedIn());
  const [isAdmin, setAdmin] = useState(checkAdmin());

  // const position = usePosition(); // geolocation hook

  const location = useLocation(); // router hook

  const showBottomBar = ['/', '/shuttles', '/news', '/drivers', '/tracker'].includes(location.pathname);

  const handleLogin = (token: string, refreshToken: string) => {
    setSignedIn(true);
  }

  const handleLogout = () => {
    setSignedIn(false);
  }

  // let cachedPosition = position;

  // const onPositionChanged = (lat: number, lng: number) => {
  //   console.log('app on position changed, old:', position);
  //   if (position?.lat && position?.lng) {
  //     console.log('app comparing', Math.abs(lat - position?.lat), Math.abs(lng - position?.lng));
  //     if (Math.abs(lat - position?.lat) < 0.00001 && Math.abs(lng - position?.lng) < 0.00001) {
  //       console.log('do nothing');
  //       return;
  //     }
  //   }
  //   console.log('app set position: ', lat, lng);
  //   const cachedPosition = {lat, lng};
  //   setPosition(cachedPosition);
  // }

  useEffect(() => {
    setAdmin(checkAdmin());
  }, [isSignedIn])

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated: !!isAdmin,
    authenticationPath: '/login'
  };

  const pollInterval = +(process.env.REACT_APP_POLL_INTERVAL || 1000);

  console.log('======> render app');

  return (
    <ApolloProvider client={client}>
      <Wrapper apiKey={process.env.REACT_APP_MAPS_API_KEY || ''}>
        <Switch>
          <Route path="/login">
            <Login onLogin={handleLogin} />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/help">
            <Help />
          </Route>

          <ProtectedRoute isAuthenticated={isSignedIn} authenticationPath="/login" path="/tracker">
            <Tracker />
            <NavDrawerButton hasShadow onClick={() => toggleDrawer(!isDrawerOpen)} />
          </ProtectedRoute>

          <Route exact path={["/", "/shuttles"]}>
            <Shuttles isAdmin={isAdmin} pollInterval={pollInterval} />
            <NavDrawerButton hasShadow onClick={() => toggleDrawer(!isDrawerOpen)} />
          </Route>
          <ProtectedRoute {...defaultProtectedRouteProps} exact path="/shuttles/new">
            <AddShuttle />
          </ProtectedRoute>
          <ProtectedRoute {...defaultProtectedRouteProps} path="/shuttles/:shuttleId">
            <EditShuttle />
          </ProtectedRoute>

          <Route exact path="/news">
            <News isAdmin={isAdmin} />
            <NavDrawerButton onClick={() => toggleDrawer(!isDrawerOpen)} />
          </Route>
          <ProtectedRoute {...defaultProtectedRouteProps} exact path="/news/new">
            <AddNews />
          </ProtectedRoute>
          <ProtectedRoute {...defaultProtectedRouteProps} path="/news/:postId">
            <EditNews />
          </ProtectedRoute>

          <ProtectedRoute {...defaultProtectedRouteProps} exact path="/drivers">
            <Drivers isAdmin={isAdmin} />
            <NavDrawerButton onClick={() => toggleDrawer(!isDrawerOpen)} />
          </ProtectedRoute>
          <ProtectedRoute {...defaultProtectedRouteProps} exact path="/drivers/new">
            <AddDriver />
          </ProtectedRoute>
          <ProtectedRoute {...defaultProtectedRouteProps} path="/drivers/:driverId">
            <EditDriver />
          </ProtectedRoute>
          
        </Switch>
      </Wrapper>
      {showBottomBar &&
        <BottomNav isAdmin={isAdmin} isSignedIn={isSignedIn} />
      }
      <NavDrawer isOpen={isDrawerOpen} isSignedIn={isSignedIn} isAdmin={isAdmin} onClose={() => toggleDrawer(false)} onLogout={handleLogout} />
    </ApolloProvider>
  );
}

export default App;
