import { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import Shuttle from '../../models/Shuttle';
import SVG from 'react-inlinesvg';
import ShuttleDetails from '../ShuttleDetails/ShuttleDetails';
import plusIcon from '../../ic_plus.svg';
import ShuttleItem from '../ShuttleItem/ShuttleItem';
import React from 'react';
import { usePosition } from '../../hooks/usePosition';

interface ShuttleData {
  shuttlesByLocation: Shuttle[];
}

export const GET_SHUTTLES = gql`
  query GetShuttles($lat: Float, $lng: Float) {
    shuttlesByLocation(lat: $lat, lng: $lng) {
      id
      name
      color
      sponsorName
      sponsorWebsite
      photo
      lat
      lng
      distance
      online
      updatedAt
    }
  }
`;

interface ShuttlesListProps {
  isAdmin: boolean;
  selectedShuttle?: Shuttle;
  onShuttleSelected: (shuttle?: Shuttle) => void;
  pollInterval: number;
}

function areEqual(prevProps: ShuttlesListProps, nextProps: ShuttlesListProps): boolean {
  if (prevProps.isAdmin !== nextProps.isAdmin) { return false; }
  if (prevProps.selectedShuttle !== nextProps.selectedShuttle) { return false; }
  return true;
}

export const ShuttlesList = React.memo(({ isAdmin, selectedShuttle, onShuttleSelected, pollInterval }: ShuttlesListProps) => {
  const position = usePosition(); // geolocation hook
  const { loading, error, data, refetch } = useQuery<ShuttleData>(GET_SHUTTLES, { variables: { lat: position.position?.lat, lng: position.position?.lng },
    pollInterval: pollInterval,
  });
  const [isExpanded, setExpanded] = useState<Boolean>(false);

  console.log('shuttles location', position.position?.lat, position.position?.lng)

  // if position changes, refetch shuttles
  useEffect(() => {
    if (position.position?.lat && position.position?.lng) {
      console.log('refetch shuttle list');
      refetch();
    }
  }, [position, refetch]);

  // set expanded or collapsed based on selected shuttle
  useEffect(() => {
    if (selectedShuttle) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [selectedShuttle]);

  console.log('shuttles', data?.shuttlesByLocation);

  if (loading) return (
    <p>Loading...</p>
  );
  if (error) return (
    <p>{error.message}</p>
  );

  const toggleExpanded = () => {
    if (isExpanded && selectedShuttle) {
      onShuttleSelected(undefined);
    }
    setExpanded(!isExpanded);
  }

  const handleDeleteShuttle = () => {
    onShuttleSelected(undefined);
  }

  const panelHeight = isExpanded ? '45%' : '25%';

  console.log('render shuttles, selected=', selectedShuttle);

  return (
    <div className="text-base flex flex-col items-center fixed bottom-20 w-full bg-white rounded-t-xl text-center shadow-2xl" style={{height: panelHeight}}>
        <button className="focus:outline-none bg-gray-300 rounded-full h-1.5 w-14 my-2" onClick={toggleExpanded} />
        <div className="overflow-y-scroll w-full">
            {selectedShuttle &&
            <ShuttleDetails shuttle={selectedShuttle} isAdmin={isAdmin} onDelete={handleDeleteShuttle} />
            }
            {!selectedShuttle &&
            <ul className="text-left">
                {data && data.shuttlesByLocation.map(shuttle => (
                <ShuttleItem key={shuttle.id} shuttle={shuttle} onShuttleSelected={onShuttleSelected} />
                ))}
            </ul>
            }
        </div>
        {isAdmin &&
            <a href="/shuttles/new" className="absolute flex items-center justify-center bottom-4 right-4 bg-primary text-white rounded-full w-10 h-10 shadow-2xl">
            <SVG src={plusIcon} />
            </a>
        }
    </div>
  );
}, areEqual);
// });

export default ShuttlesList;
