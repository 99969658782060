import axios from 'axios';
import jwtDecode from 'jwt-decode';

const checkSignedIn = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const decoded: any = jwtDecode(token);
    const now = new Date().valueOf();
    const expiresAt = decoded.exp * 1000;
    return expiresAt > now;
  }
  return false;
};

const checkAdmin = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const decoded: any = jwtDecode(token);
    const now = new Date().valueOf();
    const expiresAt = decoded.exp * 1000;
    return expiresAt > now && decoded.admin;
  }
  return false;
};

export interface LoginResponse {
  token: string
  refreshToken: string
}

const login = async (email: string, password: string): Promise<LoginResponse> => {
  console.log('========> login');
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_HOST}/auth/login`, {
        email: email,
        password: password
      });
      const token = response!.data.token;
      const refreshToken = response!.data.refreshToken;
      console.log('token', token);
      console.log('refreshToken', refreshToken);
      if (token && refreshToken) {
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        resolve({token, refreshToken});
      } else {
        reject('Sorry, please try a different email or password.');
      }
    } catch (error) {
      console.log(error)
      reject('Sorry, please try a different email or password.');
    }
  });
};

const refresh = async (): Promise<LoginResponse> => {
  console.log('========> refresh token');
  return new Promise(async (resolve, reject) => {
    const authToken = localStorage.getItem('refreshToken');
    if (!authToken) {
      reject('No refresh token.');
      return;
    }
    try {
      console.log('requesting token refresh with token', authToken);
      const response = await axios.post(`${process.env.REACT_APP_HOST}/auth/token`, null, { headers: {
        'Authorization': `Bearer ${authToken}`
      }});
      const token = response!.data.token;
      const refreshToken = response!.data.refreshToken;
      console.log('token', token);
      console.log('refreshToken', refreshToken);
      if (token && refreshToken) {
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        resolve({token, refreshToken});
      } else {
        reject('Sorry, please try a different email or password.');
      }
    } catch (error) {
      console.error('erorr refreshing token', error);
      reject('Sorry, please try a different email or password.');
    }
  });
};

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
};

export { checkSignedIn, checkAdmin, login, logout, refresh }