import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, Reference, useMutation, useQuery } from '@apollo/client';
import Post from '../../models/Post';
import SVG from 'react-inlinesvg';
import backIcon from '../../ic_chevron_left.svg';
import { useHistory } from "react-router-dom";
import Loader from '../Loader/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface PostParams {
  postId: string;
}

interface PostData {
  post: Post;
}

export function EditNews() {

  const GET_POST = gql`
    query GetPost($id: Int!) {
      post(id: $id) {
        id
        title
        body
        photo
        publishedAt
      }
    }
  `;

  const UPDATE_POST = gql`
    mutation UpdatePost($id: Int!, $post: PostInput!, $file: Upload) {
      updatePost(id: $id, post: $post, file: $file) {
        id
        title
        body
        photo
        publishedAt
      }
    }
  `;

  const DELETE_POST = gql`
    mutation DeletePost($id: Int!) {
      deletePost(id: $id)
    }
  `;

  const { postId }: PostParams = useParams();
  const parsedPostId = parseInt(postId);
  const { loading: queryLoading, error: queryError, data } = useQuery<PostData>(GET_POST, { variables: { id: parsedPostId } });
  const [post, setPost] = useState<Post>();
  const [updatePost, { loading: mutationLoading, error: mutationError, data: updatedData }] = useMutation(UPDATE_POST);
  const [file, setFile] = useState<File>();
  const history = useHistory();

  const [deletePost] = useMutation(DELETE_POST, {
    update(cache, { data: { deletePost } }) {
      cache.modify({
        fields: {
          posts(existingPosts = [], { readField }) {
            return existingPosts.filter((p: Reference) => {
              return deletePost.id !== readField('id', p);
            });
          }
        }
      });
    }
  });

  useEffect(() => {
    if (data) {
      setPost(data.post);
    }
    if (updatedData) {
      console.log('post updated: ', updatedData)
      history.push('/news');
    }
  }, [data, history, updatedData])

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (post) {
      setPost({...post, [event.target.name]: event.target.value});
    }
  }

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (post) {
      console.log('update post')
      updatePost({variables: {
        id: parsedPostId, 
        post: { 
          title: post.title, body: post.body, publishedAt: post.publishedAt 
        },
        file: file
      }});
    }
    event.preventDefault();
  }

  const handleDateChange = (date: Date) => {
    if (post) {
      setPost({...post, publishedAt: date});
    }
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { validity, files } = event.target;
    const file = files?.item(0);
    if (file && validity.valid) {
      setFile(file);
    }
  }

  const handleDelete = async (postId: number) => {
    await deletePost({ 
      variables: { id: postId },
    });
    history.goBack();
  }

  return (
    <div className="text-neutral">
      <header className="h-14 border-b border-gray-300 fixed w-full top-0 bg-white flex justify-center items-center">
        <a href="/news" className="absolute left-5"><SVG src={backIcon} /></a>
        <h1>Edit News</h1>
      </header>
      <div className="mt-14 p-4">
        {queryLoading &&
          <Loader />
        }
        {queryError &&
         <p className="bg-red-100 text-red-800 p-4">{queryError.message}</p>
        }
        {post &&
          <form onSubmit={handleOnSubmit}>
            {mutationError && <p className="bg-red-100 text-red-800 p-1">{mutationError.message}</p>}

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="title">Title</label>
              <input id="title" name="title" type="text" className="block w-full outline-none" value={post.title} onChange={handleOnChange} />
            </div>

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="body">Excerpt</label>
              <input id="body" name="body" type="text" className="block w-full outline-none" value={post.body} onChange={handleOnChange} />
            </div>

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
            <label className="text-xs block" htmlFor="photo">Photo</label>
              <input id="photo" type="file" onChange={handleFileChange} />
            </div>
            {post.photo && 
              <img src={post.photo} alt="Post" className="rounded-lg mb-4" />
            }

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="publishedAt">Published</label>
              {<DatePicker id="publishedAt" selected={new Date(post.publishedAt.toString())} showTimeSelect dateFormat="MMM d, yyyy h:mm a" onChange={handleDateChange} />}
            </div>

            {mutationLoading &&
              <button type="submit" disabled className="rounded-lg bg-primary text-white block w-full text-lg p-4 font-semibold mb-3">Saving...</button>
            }
            {!mutationLoading &&
              <button type="submit" className="rounded-lg bg-primary text-white block w-full text-lg p-4 font-semibold mb-3">Save</button>
            }

            {!mutationLoading && post.id && 
              <button 
                type="button"
                className="rounded-lg bg-red-600 text-white block w-full text-lg p-4 font-semibold mb-3"
                onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDelete(post.id) }}
              >Delete</button>
            }
          </form>
        }
      </div>
    </div>
  );
}

export default EditNews;
