import React from 'react';
import SVG from 'react-inlinesvg';
import backIcon from '../../ic_chevron_left.svg';
import { useHistory } from "react-router-dom";


export function About() {
  const history = useHistory();

  function goBack() {
    history.goBack();
  }

  return (
    <div className="text-neutral">
      <header className="h-14 border-b border-gray-300 fixed w-full top-0 bg-white flex justify-center items-center">
        <button className="absolute left-5" onClick={() => goBack()}><SVG src={backIcon} /></button>
        <h1>About LBI Shuttle</h1>
      </header>
      <div className="mt-14 p-4">
        <p>Information about the LBI Shuttle app will go here.</p>
      </div>
    </div>
  );
}

export default About;
