import React from 'react';
import SVG from 'react-inlinesvg';
import plusIcon from '../../ic_plus.svg';
import { gql, useQuery } from '@apollo/client';
import User from '../../models/User';
import { useRouteMatch } from 'react-router-dom';
import DriverItem from '../DriverItem/DriverItem';
import Loader from '../Loader/Loader';

interface DriverData {
  drivers: User[];
}

export const GET_DRIVERS = gql`
  query GetDrivers {
    drivers {
      id
      displayName
      email
      shuttles {
        id
        name
        color
      }
    }
  }
`;

interface DriversProps {
  isAdmin?: boolean;
}

export function Drivers({ isAdmin }: DriversProps) {
  const match = useRouteMatch();
  const { loading, error, data } = useQuery<DriverData>(GET_DRIVERS);
  return (
    <div className="pb-20 text-neutral">
      <header className="h-14 border-b border-gray-300 fixed w-full top-0 bg-white flex justify-center items-center">
        <h1>Drivers</h1>
      </header>
      <div className="pt-14">
        {loading &&
          <div className="pt-4">
            <Loader/>
          </div>
        }
        {error &&
          <p className="bg-red-100 text-red-800 p-4">{error.message}</p>
        }
        <ul>
          {data && data.drivers.map(driver => (
            <li key={driver.id}>
              {isAdmin && 
                <a href={`${match.path}/${driver.id}`} className="block">
                  <DriverItem driver={driver}/>
                </a>
              }
              {!isAdmin && 
                <DriverItem driver={driver}/>
              }
            </li>
          ))}
        </ul>
      </div>
      <a href="/drivers/new" className="absolute flex items-center justify-center bottom-24 right-4 bg-primary text-white rounded-full w-10 h-10 shadow-2xl">
        <SVG src={plusIcon} />
      </a>
    </div>
  );
}

export default Drivers;
