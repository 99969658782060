import React from 'react';
import SVG from 'react-inlinesvg';
import { NavLink } from "react-router-dom";

import driversIconOff from './ic_drivers_off.svg';
import driversIconOn from './ic_drivers_on.svg';
import newsIconOff from './ic_news_off.svg';
import newsIconOn from './ic_news_on.svg';
import shuttlesIconOff from './ic_shuttles_off.svg';
import shuttlesIconOn from './ic_shuttles_on.svg';
import trackerIconOff from './ic_tracker_off.svg';
import trackerIconOn from './ic_tracker_on.svg';
interface BottomNavProps {
  isAdmin: boolean;
  isSignedIn: boolean;
}

export function BottomNav({ isAdmin, isSignedIn }: BottomNavProps) {
  return (
    <nav className="fixed bottom-0 w-full bg-gray-50 h-20 text-xs border-t border-gray-300">
      <ul className="flex h-full">
        {isSignedIn && !isAdmin &&
          <li className="flex-1">
            <NavLink className="flex h-full justify-center items-center flex-col" activeClassName="text-primary active" exact to="/tracker">
              <SVG src={trackerIconOff} className="inactive-only w-8 h-8" />
              <SVG src={trackerIconOn} className="active-only w-8 h-8" />
              <span>Tracker</span>
            </NavLink>
          </li>
        }
        <li className="flex-1">
          <NavLink className="flex h-full justify-center items-center flex-col" activeClassName="text-primary active" exact to="/shuttles">
            <SVG src={shuttlesIconOff} className="inactive-only w-8 h-8" />
            <SVG src={shuttlesIconOn} className="active-only w-8 h-8" />
            <span>Shuttles</span>
          </NavLink>
        </li>
        <li className="flex-1">
          <NavLink className="flex h-full justify-center items-center flex-col" activeClassName="text-primary active" to="/news">
            <SVG src={newsIconOff} className="inactive-only w-8 h-8" />
            <SVG src={newsIconOn} className="active-only w-8 h-8" />
            <span>News</span>
          </NavLink>
        </li>
        {isAdmin &&
          <li className="flex-1">
            <NavLink className="flex h-full justify-center items-center flex-col" activeClassName="text-primary active" to="/drivers">
              <SVG src={driversIconOff} className="inactive-only w-8 h-8" />
              <SVG src={driversIconOn} className="active-only w-8 h-8" />
              <span>Drivers</span>
            </NavLink>
          </li>
        }
      </ul>
    </nav>
  );
}

export default BottomNav;
