import { useState } from 'react';
import Map from '../Map/Map';
import Shuttle from '../../models/Shuttle';
import React from 'react';
import ShuttlesList from '../ShuttlesList/ShuttlesList';

interface ShuttlesProps {
  isAdmin: boolean;
  pollInterval: number;
}

function areEqual(prevProps: ShuttlesProps, nextProps: ShuttlesProps): boolean {
  if (prevProps.isAdmin !== nextProps.isAdmin) { return false; }
  return true;
}

export const Shuttles = React.memo(({ isAdmin, pollInterval }: ShuttlesProps) => {

  const [selectedShuttle, setSelectedShuttle] = useState<Shuttle|undefined>(undefined);

  const onShuttleSelected = (shuttle?: Shuttle) => {
    setSelectedShuttle(shuttle);
  }

  const mapHeight = '80%';

  console.log('render shuttles, selected=', selectedShuttle);

  return (
    <div className="h-full pb-20 text-neutral">
      <div style={{height: mapHeight}}>
        <Map selectedShuttle={selectedShuttle} onShuttleSelected={onShuttleSelected} pollInterval={pollInterval} />
      </div>
      <ShuttlesList isAdmin={isAdmin} selectedShuttle={selectedShuttle} onShuttleSelected={onShuttleSelected} pollInterval={pollInterval} />
    </div>
  );
}, areEqual);
// });

export default Shuttles;
