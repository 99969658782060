import React from 'react';
import { gql, useQuery } from '@apollo/client';
import PostItem from '../PostItem/PostItem';
import Post from '../../models/Post'
import { useRouteMatch } from 'react-router-dom';
import Loader from '../Loader/Loader';

interface PostData {
  posts: Post[];
}

export const GET_POSTS = gql`
  query GetPosts {
    posts {
      id
      title
      body
      photo
      publishedAt
    }
  }
`;

interface PostsProps {
  isAdmin?: boolean;
}

export function Posts({ isAdmin }: PostsProps) {
  const match = useRouteMatch();
  const { loading, error, data } = useQuery<PostData>(GET_POSTS);

  if (loading) return (
    <div className="pt-4">
      <Loader/>
    </div>
  );
  if (error) return (
    <p className="bg-red-100 text-red-800 p-4">{error.message}</p>
  );

  return (
    <ul>
      {data && data.posts.map(post => (
        <li key={post.id}>
          {isAdmin && 
            <a href={`${match.path}/${post.id}`} className="block">
              <PostItem post={post}/>
            </a>
          }
          {!isAdmin && 
            <PostItem post={post}/>
          }
        </li>
      ))}
    </ul>
  );
}

export default Posts;
