import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import logo from '../../logo.png';
import closeIcon from './ic_close.svg';
import SVG from 'react-inlinesvg';
import { NavLink } from "react-router-dom";
import { login, checkAdmin } from '../../auth';
import { gql, useMutation } from '@apollo/client';

interface LoginProps {
  onLogin: (token: string, refreshToken: string) => void;
}

export function Login({ onLogin }: LoginProps) {
  const START_TRACKING = gql`
    mutation StartTracking {
      startTracking {
        id
        name
        color
        lat
        lng
        online
      }
    }
  `;

  const [startTracking] = useMutation(START_TRACKING);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  const submit = async () => {
    try {
      const { token, refreshToken } = await login(email, password);
      console.log('logged in with ', token, refreshToken);

      const isAdmin = checkAdmin();

      if (!isAdmin) {
        await startTracking();
      }

      onLogin(token, refreshToken);

      // goBack();
      if (isAdmin) {
        history.push('/')
      } else {
        history.push('/tracker')
      }
    } catch (error) {
      setError('Sorry, please try a different email or password.');
    }
  };

  function goBack() {
    history.goBack();
  }
  
  return (
    <div className="p-4 text-neutral text-base">
      <button className="absolute l-4 t-4" onClick={() => goBack()}><SVG src={closeIcon} /></button>
      <img src={logo} alt="LBI Shuttle" className="w-60 mx-auto mt-20 mb-8" />

      <form onSubmit={event => {
        submit();
        event.preventDefault();
      }}>
        {error && <p className="text-xs text-red-500 mb-1">{error}</p>}
        {!error && <p className="text-xs mb-1">&nbsp;</p>}

        <div className="border border-gray-300 rounded-lg p-2 mb-4">
          <label className="text-xs block" htmlFor="email">Email address</label>
          <input id="email" type="email" className="block w-full outline-none" value={email} onChange={event => setEmail(event.target.value)} />
        </div>

        <div className="border border-gray-300 rounded-lg p-2 mb-4">
          <label className="text-xs block" htmlFor="password">Password</label>
          <input id="password" type="password" className="block w-full outline-none" autoComplete='password' value={password} onChange={event => setPassword(event.target.value)} />
        </div>

        <button type="submit" className="rounded-lg bg-primary text-white block w-full text-lg p-4 font-semibold mb-3">Sign In</button>
      </form>

      <NavLink className="text-primary text-sm" to="/">
        <span>Forgot your password?</span>
      </NavLink>
    </div>
  );
}

export default Login;
