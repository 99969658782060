import React from 'react';

interface NavDrawerButtonProps {
  hasShadow: boolean;
  onClick: () => void;
}

export function NavDrawerButton({ hasShadow, onClick }: NavDrawerButtonProps) {

  const shadow = hasShadow ? 'shadow-md' : '';
  
  return (
    <button className={`bg-white rounded-full absolute left-2 top-2 w-10 h-10 flex justify-center items-center flex-col ${shadow} pointer-events-auto`} onClick={onClick}>
      <div className="bg-gray-600 rounded-full h-0.5 w-5"/>
      <div className="bg-gray-600 rounded-full h-0.5 w-5 mt-1 mb-1"/>
      <div className="bg-gray-600 rounded-full h-0.5 w-5"/>
    </button>
  );
}

NavDrawerButton.defaultProps = {
  hasShadow: false,
  onClick: () => {}
};

export default NavDrawerButton;
