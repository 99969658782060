import React from 'react';
import Posts from '../Posts/Posts';
import SVG from 'react-inlinesvg';
import plusIcon from '../../ic_plus.svg';

interface NewsProps {
  isAdmin?: boolean;
}

export function News({ isAdmin }: NewsProps) {
  return (
    <div className="pb-20 text-neutral">
      <header className="h-14 border-b border-gray-300 fixed w-full top-0 bg-white flex justify-center items-center">
        <h1>News</h1>
      </header>
      <div className="pt-14">
        <Posts isAdmin={isAdmin} />
      </div>
      {isAdmin &&
        <a href="/news/new" className="absolute flex items-center justify-center bottom-24 right-4 bg-primary text-white rounded-full w-10 h-10 shadow-2xl">
          <SVG src={plusIcon} />
        </a>
      }
    </div>
  );
}

export default News;
