import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { gql, Reference, useMutation, useQuery } from '@apollo/client';
import User from '../../models/User';
import SVG from 'react-inlinesvg';
import backIcon from '../../ic_chevron_left.svg';
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';
import Shuttle from '../../models/Shuttle';

interface DriverParams {
  driverId: string;
}

interface DriverData {
  driver: User;
}

interface ShuttleData {
  shuttles: [Shuttle];
}

export function EditDriver() {

  const GET_SHUTTLES = gql`
    query GetShuttles {
      shuttles {
        id
        name
        color
      }
    }
  `;

  const GET_DRIVER = gql`
    query GetDriver($id: Int!) {
      driver(id: $id) {
        id
        displayName
        email
        shuttles {
          id
          name
        }
      }
    }
  `;

  const UPDATE_DRIVER = gql`
    mutation UpdateDriver($id: Int!, $driver: DriverInput!) {
      updateDriver(id: $id, driver: $driver) {
        id
        displayName
        email
      }
    }
  `;

  const DELETE_DRIVER = gql`
    mutation DeleteDriver($id: Int!) {
      deleteDriver(id: $id)
    }
  `;

  const { driverId }: DriverParams = useParams();
  const parsedDriverId = parseInt(driverId);
  const { loading: queryLoading, error: queryError, data } = useQuery<DriverData>(GET_DRIVER, { variables: { id: parsedDriverId } });
  const [driver, setDriver] = useState<User>();
  const [updateDriver, { loading: mutationLoading, error: mutationError, data: updatedData }] = useMutation(UPDATE_DRIVER);
  const { loading: shuttlesLoading, error: shuttlesError, data: shuttlesData } = useQuery<ShuttleData>(GET_SHUTTLES);
  const [shuttles, setShuttles] = useState<[Shuttle]>();
  const [shuttleId, setShuttleId] = useState<number>();

  const [deleteDriver] = useMutation(DELETE_DRIVER, {
    update(cache, { data: { deleteDriver } }) {
      cache.modify({
        fields: {
          posts(existingDrivers = [], { readField }) {
            return existingDrivers.filter((d: Reference) => {
              return deleteDriver.id !== readField('id', d);
            });
          }
        }
      });
    }
  });

  const history = useHistory();

  useEffect(() => {
    if (data) {
      setDriver(data.driver);
      if (data.driver.shuttles && data.driver.shuttles.length > 0) {
        setShuttleId(data.driver.shuttles[0].id);
      }
    }
    if (shuttlesData) {
      setShuttles(shuttlesData.shuttles);
    }
    if (updatedData) {
      console.log('driver updated: ', updatedData)
      history.push('/drivers');
    }
  }, [data, history, updatedData, shuttlesData])

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (driver) {
      setDriver({...driver, [event.target.name]: event.target.value});
    }
  }

  const handleShuttleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setShuttleId(parseInt(event.target.value));
  }

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (driver) {
      console.log('update driver')
      updateDriver({variables: {
        id: parsedDriverId, 
        driver: { 
          displayName: driver.displayName, email: driver.email, password: driver.password, shuttleId
        },
      }});
    }
    event.preventDefault();
  }

  const handleDelete = async (userId: number) => {
    await deleteDriver({ 
      variables: { id: userId },
    });
    history.goBack();
  }

  return (
    <div className="text-neutral">
      <header className="h-14 border-b border-gray-300 fixed w-full top-0 bg-white flex justify-center items-center">
        <a href="/drivers" className="absolute left-5"><SVG src={backIcon} /></a>
        <h1>Edit Driver</h1>
      </header>
      <div className="mt-14 p-4">
        {queryLoading &&
          <Loader />
        }
        {queryError &&
          <p className="bg-red-100 text-red-800 p-4">{queryError.message}</p>
        }
        {driver &&
          <form onSubmit={handleOnSubmit}>
            {mutationError && <p className="bg-red-100 text-red-800 p-1">{mutationError.message}</p>}

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="displayName">Name</label>
              <input id="displayName" name="displayName" type="text" className="block w-full outline-none" value={driver.displayName} onChange={handleOnChange} />
            </div>

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="email">Email</label>
              <input id="email" name="email" type="email" className="block w-full outline-none" value={driver.email} onChange={handleOnChange} />
            </div>

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="password">Password</label>
              <input id="password" name="password" type="password" className="block w-full outline-none" value={driver.password || ''} onChange={handleOnChange} />
            </div>

            {!shuttlesLoading && !shuttlesError && shuttles &&
              <div className="border border-gray-300 rounded-lg p-2 mb-4">
                <label className="text-xs block" htmlFor="shuttleId">Shuttle</label>
                <select id="shuttleId" value={shuttleId} className="w-full" onChange={handleShuttleChange}>
                  <option>Select a shuttle</option>
                  {shuttles.map((s) => (
                    <option key={s.id} value={s.id}>{s.name}</option>
                  ))}
                </select>
              </div>
            }

            {mutationLoading &&
              <button type="submit" disabled className="rounded-lg bg-primary text-white block w-full text-lg p-4 font-semibold mb-3">Saving...</button>
            }
            {!mutationLoading &&
              <button type="submit" className="rounded-lg bg-primary text-white block w-full text-lg p-4 font-semibold mb-3">Save</button>
            }

            {!mutationLoading && driver.id && 
              <button 
                type="button"
                className="rounded-lg bg-red-600 text-white block w-full text-lg p-4 font-semibold mb-3"
                onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDelete(driver.id) }}
              >Delete</button>
            }     
          </form>
        }
      </div>
    </div>
  );
}

export default EditDriver;
