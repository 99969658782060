import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import User from '../../models/User';
import SVG from 'react-inlinesvg';
import backIcon from '../../ic_chevron_left.svg';
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Shuttle from '../../models/Shuttle';

export const GET_SHUTTLES = gql`
  query GetShuttles {
    shuttles {
      id
      name
      color
    }
  }
`;

export const CREATE_DRIVER = gql`
  mutation CreateDriver($driver: DriverInput!) {
    createDriver(driver: $driver) {
      id
      displayName
      email
    }
  }
`;

interface ShuttleData {
  shuttles: [Shuttle];
}

export function AddDriver() {
  const [driver, setDriver] = useState<User>({id: 0, displayName: '', email: '', password: ''});
  const [createDriver, { loading: mutationLoading, error: mutationError, data: updatedData }] = useMutation(CREATE_DRIVER);
  const { loading: shuttlesLoading, error: shuttlesError, data: shuttlesData } = useQuery<ShuttleData>(GET_SHUTTLES);
  const [shuttles, setShuttles] = useState<[Shuttle]>();
  const [shuttleId, setShuttleId] = useState<number>();
  const history = useHistory();

  useEffect(() => {
    if (shuttlesData) {
      setShuttles(shuttlesData.shuttles);
    }
    if (updatedData) {
      console.log('driver created: ', updatedData)
      history.push('/drivers');
    }
  }, [history, updatedData, shuttlesData])

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (driver) {
      setDriver({...driver, [event.target.name]: event.target.value});
    }
  }

  const handleShuttleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setShuttleId(parseInt(event.target.value));
  }

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (driver) {
      console.log('update driver')
      createDriver({variables: {
        driver: { 
          displayName: driver.displayName, email: driver.email, password: driver.password, shuttleId
        },
      }});
    }
    event.preventDefault();
  }

  return (
    <div className="text-neutral">
      <header className="h-14 border-b border-gray-300 fixed w-full top-0 bg-white flex justify-center items-center">
        <a href="/drivers" className="absolute left-5"><SVG src={backIcon} /></a>
        <h1>Add Driver</h1>
      </header>
      <div className="mt-14 p-4">
        {driver &&
          <form onSubmit={handleOnSubmit}>
            {mutationError && <p className="bg-red-100 text-red-800 p-1">{mutationError.message}</p>}

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="displayName">Name</label>
              <input id="displayName" name="displayName" type="text" className="block w-full outline-none" value={driver.displayName} onChange={handleOnChange} />
            </div>

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="email">Email</label>
              <input id="email" name="email" type="email" className="block w-full outline-none" value={driver.email} onChange={handleOnChange} />
            </div>

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="password">Password</label>
              <input id="password" name="password" type="password" className="block w-full outline-none" value={driver.password} onChange={handleOnChange} />
            </div>

            {!shuttlesLoading && !shuttlesError && shuttles &&
              <div className="border border-gray-300 rounded-lg p-2 mb-4">
                <label className="text-xs block" htmlFor="shuttleId">Shuttle</label>
                <select id="shuttleId" value={shuttleId} className="w-full" onChange={handleShuttleChange}>
                  <option>Select a shuttle</option>
                  {shuttles.map((s) => (
                    <option key={s.id} value={s.id}>{s.name}</option>
                  ))}
                </select>
              </div>
            }

            {mutationLoading &&
              <button type="submit" disabled className="rounded-lg bg-primary text-white block w-full text-lg p-4 font-semibold mb-3">Saving...</button>
            }
            {!mutationLoading &&
              <button type="submit" className="rounded-lg bg-primary text-white block w-full text-lg p-4 font-semibold mb-3">Save</button>
            }
          </form>
        }
      </div>
    </div>
  );
}

export default AddDriver;
