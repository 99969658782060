import { MouseEvent } from 'react';
import Shuttle from '../../models/Shuttle';
import icon from '../../ic_shuttle_ko.svg';
import SVG from 'react-inlinesvg';

interface ShuttleItemProps {
  shuttle: Shuttle
  onShuttleSelected: (shuttle: Shuttle) => void
}

export function ShuttleItem({ shuttle, onShuttleSelected }: ShuttleItemProps) {

  const handleShuttleClick = (e: MouseEvent<HTMLAnchorElement>, shuttle: Shuttle) => {
    e.preventDefault();
    onShuttleSelected(shuttle);
  }

  const style = {
    backgroundColor: shuttle.color
  };

  const distance = shuttle.distance ? shuttle.distance : 0;

  const now = new Date();
  const updatedAt = new Date(shuttle.updatedAt.toString())
  const ms = now.getTime() - updatedAt.getTime();
  const oneHour = 1000 * 60 * 60;;
  const isOnline = shuttle.online && ms < oneHour;

  const textColor = isOnline ? 'text-neutral' : 'text-light';

  return (
    <li>
      <a href="/" className={`flex px-4 py-2 items-center ${textColor}`} onClick={(e) => handleShuttleClick(e, shuttle)}>
        <div className={`w-10 h-10 rounded-full flex justify-center items-center mr-3`} style={style}><SVG src={icon}/></div>
        <div className="flex flex-grow">
          <div className="flex-grow">
            <h2 className="text-lg font-semibold leading-snug">{shuttle.name}</h2>
            <p className="text-sm font-light leading-snug">{shuttle.sponsorName}</p>
          </div>
          {!isOnline &&
            <div className="text-sm font-light leading-snug text-light flex items-center">Offline</div>
          }
          {isOnline &&
            <div className="text-sm font-light leading-snug text-neutral flex items-center">
              {(distance / 1609).toFixed(2)} mi
            </div>
          }
        </div>
      </a>
    </li>
  )
}

export default ShuttleItem;
