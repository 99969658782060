import { useState, useEffect } from 'react';

interface Position {
  lat: number;
  lng: number;
}

export const usePosition = () => {
  const [position, setPosition] = useState<Position|undefined>();
  const [error, setError] = useState<string | null>(null);

  const onChange = ({ coords }: GeolocationPosition) => {
    setPosition({
      lat: coords.latitude,
      lng: coords.longitude,
    });
  };

  const onError = (error: GeolocationPositionError) => {
    setError(error.message);
  }

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError('Geolocation is not supported');
      return;
    }

    // const watcher = geo.watchPosition(onChange, onError, { enableHighAccuracy: false, maximumAge: 0, timeout: 20000  });
    const watcher = geo.watchPosition(onChange, onError);

    return () => geo.clearWatch(watcher);
  }, []);


  return { position, error };
}