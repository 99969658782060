import React from 'react';
import User from '../../models/User';
import SVG from 'react-inlinesvg';
import icon from '../../ic_shuttle_ko.svg';

interface DriverItemParams {
  driver: User;
}

export function DriverItem({ driver }: DriverItemParams) {
  return (
    <div className="p-4 flex items-center">
      <div className="flex-grow">
        <h2 className="text-lg font-semibold leading-snug">{driver.displayName}</h2>
        <p className="text-sm font-light leading-snug mb-1">{driver.email}</p>
      </div>
      {driver.shuttles?.map((shuttle) => {
        const style = { backgroundColor: shuttle.color };
        return(
          <div className="flex items-center" key={shuttle.id}>
            <div className={`w-4 h-4 rounded-full flex justify-center items-center mr-1`} style={style}><SVG src={icon} width="13" height="13" /></div>
            <h2 className="text-sm font-light leading-snug text-neutral">{shuttle.name}</h2>
          </div>
        );
      })}
      
    </div>
  );
}

export default DriverItem;
