import Shuttle from '../../models/Shuttle';
import icon from '../../ic_shuttle_ko.svg';
import SVG from 'react-inlinesvg';

interface ShuttleDetailsProps {
  shuttle: Shuttle;
  isAdmin: boolean;
  onDelete: () => void
}

export function ShuttleDetails({ shuttle, isAdmin, onDelete }: ShuttleDetailsProps) {

  const style = {
    backgroundColor: shuttle.color
  };

  const now = new Date();
  const updatedAt = new Date(shuttle.updatedAt.toString())
  const ms = now.getTime() - updatedAt.getTime();
  const oneHour = 1000 * 60 * 60;;
  const isOnline = shuttle.online && ms < oneHour;

  const distance = shuttle.distance ? shuttle.distance : 0;

  return (
    <div className="text-left px-4 py-2">
      <div className="flex items-center mb-4">
        <div className={`w-10 h-10 rounded-full flex justify-center items-center mr-3`} style={style}><SVG src={icon}/></div>
        <div className="flex flex-grow">
          <div className="flex-grow">
            <h2 className="text-lg font-semibold leading-snug">{shuttle.name}</h2>
            <p className="text-sm font-light leading-snug">{shuttle.sponsorName}</p>
          </div>
          {!isOnline &&
            <div className="text-sm font-light leading-snug text-light flex items-center">Offline</div>
          }
          {isOnline &&
            <div className="text-sm font-light leading-snug text-neutral flex items-center">
              {(distance / 1609).toFixed(2)} mi
            </div>
          }
        </div>      
      </div>

      {isAdmin &&
        <a href={`shuttles/${shuttle.id}`} className="rounded-lg bg-secondary text-white block w-full text-lg p-4 font-semibold mb-3 text-center">Edit</a>
      }
      
      {shuttle.sponsorWebsite &&
        <a href={shuttle.sponsorWebsite} className="rounded-lg bg-primary text-white block w-full text-lg p-4 font-semibold mb-3 text-center">Visit Sponsor</a>
      }
      {shuttle.photo &&
        <img src={shuttle.photo} alt={shuttle.name} className="rounded-lg m-auto" />
      }
    </div>
  );
}

export default ShuttleDetails;
