import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import Post from '../../models/Post';
import SVG from 'react-inlinesvg';
import backIcon from '../../ic_chevron_left.svg';
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const CREATE_POST = gql`
  mutation CreatePost($post: PostInput!, $file: Upload) {
    createPost(post: $post, file: $file) {
      id
      title
      body
      photo
      publishedAt
    }
  }
`;

export function AddNews() {
  const [post, setPost] = useState<Post>({id: 0, title: '', body: '', photo: '', publishedAt: new Date()});
  const [createPost, { loading: mutationLoading, error: mutationError, data: updatedData }] = useMutation(CREATE_POST);
  const [file, setFile] = useState<File>();

  const history = useHistory();

  useEffect(() => {
    if (updatedData) {
      console.log('post created: ', updatedData)
      history.push('/news');
    }
  }, [history, updatedData])

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (post) {
      setPost({...post, [event.target.name]: event.target.value});
    }
  }

  const handleOnSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (post) {
      console.log('update post')
      createPost({variables: {
        post: { 
          title: post.title, body: post.body, publishedAt: post.publishedAt 
        },
        file: file
      }});
    }
    event.preventDefault();
  }

  const handleDateChange = (date: Date) => {
    if (post) {
      setPost({...post, publishedAt: date});
    }
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { validity, files } = event.target;
    const file = files?.item(0);
    if (file && validity.valid) {
      setFile(file);
    }
  }

  return (
    <div className="text-neutral">
      <header className="h-14 border-b border-gray-300 fixed w-full top-0 bg-white flex justify-center items-center">
        <a href="/news" className="absolute left-5"><SVG src={backIcon} /></a>
        <h1>Add News</h1>
      </header>
      <div className="mt-14 p-4">
        {post &&
          <form onSubmit={handleOnSubmit}>
            {mutationError && <p className="bg-red-100 text-red-800 p-1">{mutationError.message}</p>}

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="title">Title</label>
              <input id="title" name="title" type="text" className="block w-full outline-none" value={post.title} onChange={handleOnChange} />
            </div>

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="body">Excerpt</label>
              <input id="body" name="body" type="text" className="block w-full outline-none" value={post.body} onChange={handleOnChange} />
            </div>

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
            <label className="text-xs block" htmlFor="photo">Photo</label>
              <input id="photo" type="file" onChange={handleFileChange} />
            </div>
            {post.photo && 
              <img src={post.photo} alt="Post" className="rounded-lg mb-4" />
            }

            <div className="border border-gray-300 rounded-lg p-2 mb-4">
              <label className="text-xs block" htmlFor="publishedAt">Published</label>
              {<DatePicker id="publishedAt" selected={new Date(post.publishedAt.toString())} showTimeSelect dateFormat="MMM d, yyyy h:mm a" onChange={handleDateChange} />}
            </div>

            {mutationLoading &&
              <button type="submit" disabled className="rounded-lg bg-primary text-white block w-full text-lg p-4 font-semibold mb-3">Saving...</button>
            }
            {!mutationLoading &&
              <button type="submit" className="rounded-lg bg-primary text-white block w-full text-lg p-4 font-semibold mb-3">Save</button>
            }
          </form>
        }
      </div>
    </div>
  );
}

export default AddNews;
